import React, { useEffect, useState } from "react";
import Navbar from "../Components/Header/Navbar";
import background from "../img/Background-header.jpg";
import Footer from "../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import imagedevice from "../img/field.jpg";
import imagedevice2 from "../img/smst.jpg";
import imagedevice3 from "../img/bigst.jpg";

type Card = {
  id: number;
  title: string;
  price: number;
  description: string;
  currency: string;
  image: string;
  features: string[];
};

export const Pricing = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const password = localStorage.getItem("password");
    if (!password || password !== "elauro") {
      navigate("/password");
    }
  }, []);

  const [selectedCard, setSelectedCard] = useState<Card | null>(null);

  const cards: Card[] = [
    {
      id: 1,
      title: "Club",
      price: 30.0,
      currency:"€",
      description: "/month",
      features: [
        "Ball tracking view",
        "Team calendar",
        "Highlight autotag system",
        "Analysis tools",
        "2 teams",
        "6 month storage",
        "Recording time monthly: 20h",
      ],
      image: imagedevice, // Imagen para esta tarjeta
    },
    {
      id: 2,
      title: "Pro Club",
      price: 60.0,
      currency:"€",
      description: "/month",
      features: [
        "Ball tracking view",
        "Team calendar",
        "Highlight autotag system",
        "Analysis tools",
        "5 teams",
        "12 month storage",
        "Recording time monthly: 50h",
      ],
      image: imagedevice2, // Imagen para esta tarjeta
    },
    {
      id: 3,
      title: "Elite Club",
      price: 90.0,
      currency:"€",
      description: "/month",
      features: [
        "Ball tracking view",
        "Team calendar",
        "Highlight autotag system",
        "Analysis tools",
        "10 teams",
        "12 month storage",
        "Unlimited Recording time",
      ],
      image: imagedevice3, // Imagen para esta tarjeta
    },
  ];

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <Navbar />
        <div className="p-4">
          <div className="flex items-center space-x-4 mt-40 justify-center">
            <div className=" z-[2999]">
              <h2 className=" text-white text-4xl font-bold">
                Pricing & Plans
              </h2>
            </div>
          </div>
          <div className="flex items-center space-x-4 justify-center">
            <div className=" z-[1999] mt-10">
              <h3 className=" text-white text-2xl">
                Find the Perfect Plan for Your Club
              </h3>
            </div>
          </div>
          <div className="flex items-center space-x-4 justify-center">
            <div className=" z-[2999]">
              <p className=" text-white sm:w-[1000px] text-center mt-4">
                Explore our membership options tailored to every level of
                competition. From essential team management tools to advanced
                analytics and tracking solutions, we have the plan to fit your
                club's needs. Choose the level that matches your ambition and
                take your performance to the next level. Start today and see how
                you can transform your game!
              </p>
            </div>
          </div>

          <div className=" sm:flex items-center sm:space-x-4 mt-20 justify-center">
          {cards.map((card) => (
            <div
              key={card.id}
              className={`p-3 rounded-lg cursor-pointer transition-transform transform hover:scale-105 ${
                selectedCard?.price === card.price
                  ? "bg-gradient-to-b from-[#BE95FF] to-[#f8f8f8]"
                  : "bg-[#f8f8f8]"
              }`}
              onClick={() => setSelectedCard(card)} // Permitir seleccionar cualquier tarjeta
            >
              {/* Contenido de la tarjeta */}
              <div className={`flex flex-col items-start p-4 rounded-lg`}>
                <h4 className="font-semibold text-2xl">{card.title}</h4>
                <p className="text-xl">
                  {card.price}{card.currency}{" "}
                  <span className="text-gray-500">{card.description}</span>
                </p>
              </div>
              <img
                src={card.image} // Usar la imagen de la tarjeta
                className={`w-[300px] mb-10 rounded-lg`}
                alt={`${card.title} image`}
              />
              <div className="p-4">
                {card.features.map((feature, index) => (
                  <p key={index} className="flex items-center gap-1">
                    <FaCheck size={20} color="#BE95FF" />
                    {feature}
                  </p>
                ))}
              </div>
            </div>
          ))}
          </div>
        </div>
        <div className="flex justify-center">
          <a href="https://www.wizardfootball.com/checkout">
            <button className="mt-8 border rounded-3xl p-2 w-40 text-white border-[#be95ff] hover:bg-[#be95ff] hover:border-white">
              Buy Now
            </button>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

import React from "react";

const Downloads = () => {
  const versions = [
    {
      version: "1.0.0 (Stable version)",
      androidUrl:
        "https://downloads.wizardplatform.com/android/application-53da2574-a0e5-4a1b-a154-a2de7dbfcc34.apkd",
      iosUrl:
        "https://downloads.wizardplatform.com/ios/application-b5b9c385-6792-4f83-9fa8-6704d5947572.ipa",
    },
    {
      version: "2.0.0",
      androidUrl:
        "https://downloads.wizardplatform.com/android/application-53da2574-a0e5-4a1b-a154-a2de7dbfcc34.apkd",
      iosUrl:
        "https://downloads.wizardplatform.com/ios/application-b5b9c385-6792-4f83-9fa8-6704d5947572.ipa",
    },
  ];

  return (
    <div className="flex justify-center p-20 bg-gradient-to-b from-[#EFEFEF] to-black text-white">
      <div className="w-[1200px] p-10">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
          Download App
        </h2>
        <ul className="space-y-4">
          {versions.map((version) => (
            <li
              key={version.version}
              className="flex justify-between items-center bg-gray-100 p-4 rounded-lg shadow-md"
            >
              <span className="text-xl font-semibold text-gray-800">
                Version APK {version.version}
              </span>
              <div className="space-x-4">
                <a
                  href={version.androidUrl}
                  download
                  className={`mt-8 border rounded-3xl px-4 py-2 w-40 bg-[#be95ff] hover:bg-transparent hover:border-[#080f42] hover:text-[#080f42] ${
                    version.version === "2.0.0"
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                >
                  Android Download
                </a>
                <a
                  href={version.iosUrl}
                  download
                  className={`mt-8 border rounded-3xl px-4 py-2 w-40 bg-[#080f42] hover:bg-transparent hover:border-[#080f42] hover:text-[#080f42] ${
                    version.version === "2.0.0"
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                >
                  iOS Download
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Downloads;
